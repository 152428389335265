











































































































































































































































































import Repository from "../Repository";

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Settings extends Vue {

  config :any = {}

  options = [
    { text: 'Hidden', value: 'hidden' },
    { text: 'Optional', value: 'optional' },
    { text: 'Required', value: 'required' }
  ]


  save() {

    let config = {

      maxtokens           : this.config.maxtokens.value,
      tokenexpiration     : this.config.tokenexpiration.value,
      statedebug          : this.config.statedebug.value,

      descriptions_label  : this.config.descriptions_label.value,
      descriptions        : this.config.descriptions.value,

      details_mode        : this.config.details_mode.value,
      details_label       : this.config.details_label.value,

      location_mode       : this.config.location_mode.value,
      location_label      : this.config.location_label.value,
      location_defaults   : this.config.location_defaults.value,
      

      website_mode        : this.config.website_mode.value,
      website_label       : this.config.website_label.value,
      website_defaults    : this.config.website_defaults.value,

      careform_subject    : this.config.careform_subject.value,
      careform_body       : this.config.careform_body.value,
      careform_toast      : this.config.careform_toast.value
    }

    Repository.put("settings", { config: config })
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
          }

          this.$bvToast.toast(res.data.content, res.data.options);

          Repository.get("settings").then(res => { this.config = res.data } )
          // this could take a while
          this.$store.dispatch("pref")
        })
        .catch(() => {
          this.$bvToast.toast("settings did not save.", {
            title: "Error",
            variant: "danger"
          })
        })
  }

  purge() {

    Repository.delete("settings")
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
          }

          this.$bvToast.toast(res.data.content, res.data.options);
          this.config = res.data.data
          
          Repository.get("settings").then(res => { this.config = res.data } )
          this.$store.dispatch("pref")
        })
        .catch(error => {
          console.log(JSON.stringify(error))
          this.$bvToast.toast("Settings did not save.", {
            title: "Error",
            variant: "danger"
          })
        })

  }

  mounted() {
    Repository.get("settings")
      .then(res => { this.config = res.data } )
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

  }

}

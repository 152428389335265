
























































































































import Repository from "../Repository";

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ResetPassword extends Vue {

  email: string = ""
  captcha_image: string | null = null
  captcha_hash: string = ""
  captcha: string = ""
  showHash: boolean = false

  created() {
    this.fetchData();
  }


  doImpersonate() {
      let email = this.email
      let captcha_hash = this.captcha_hash
      let captcha = this.captcha

      if (email.length == 0 || captcha.length == 0) {
         return
      }

      this.$store
        .dispatch("impersonate", { email, captcha_hash, captcha })
        .then(() => {

          // We can't use isLogged in, because we should already be logged in
          if (this.$store.getters.messageOptions.variant == "success") {
            this.$bvToast.toast(
            this.$store.getters.message,
            this.$store.getters.messageOptions
            )

            this.$router.push("/events")
          } else {
            console.log("We were not successful")
            console.log(this.$store.getters.message)

            this.$bvToast.toast(
              this.$store.getters.message,
              this.$store.getters.messageOptions
            )
          }
        })
        .catch(err => console.log(err))
    }



  fetchData() {
    Repository.get("captcha")
      .then(res => {
        (this.captcha_image = res.data.captcha_image),
          (this.captcha_hash = res.data.captcha_hash)
      })
      .catch(function(error) {
        // @ts-ignore:disable-next-line
        this.$bvToast.toast(JSON.stringify(error))
      })
  }


  get emailstate(): boolean | null {
      if (this.email.length < 10) return null

      return true
    }

  get captchastate(): boolean | null {
      if (this.captcha.length > 4) return true

      return null
    }

  
  mounted() {

    if (!this.$store.getters.isLoggedIn)  {
      this.$router.push({ path: "/login" })
    }

    if (!this.$store.getters.isAdmin) {
      this.$router.push({ path: "/events" })
    }

  }
}



























































































import Repository from "../Repository";

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class ResetPassword extends Vue {

  email: string = ""
  captcha_image: string | null = null
  captcha_hash: string = ""
  captcha: string = ""

  created() {
    this.fetchData();
  }

  doForgotPassword() {
      if (!this.emailstate) {
        this.$bvToast.toast("This is not valid email.", {
          title: "Error",
          variant: "danger"
        })
        return
      }

      return Repository.post("resetpassword", {
        email: this.email,
        captcha_hash: this.captcha_hash,
        captcha: this.captcha
      })
        .then(res => {

          // things went well. Let's go to the login page
          if (res.data.options.variant == "success") {
            const data = res.data
            this.$store
              .dispatch("message_nextpage",  data )
              .then(() => {
                this.$router.push("/login")
            })
          }    

          this.$bvToast.toast(res.data.content, res.data.options);

          

          this.email = ""
          this.captcha = ""
          this.fetchData()
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          this.$bvToast.toast("Password was not reset")
        })
    }



  fetchData() {
    Repository.get("captcha")
      .then(res => {
        (this.captcha_image = res.data.captcha_image),
          (this.captcha_hash = res.data.captcha_hash)
      })
      .catch(function(error) {
        // @ts-ignore:disable-next-line
        this.$bvToast.toast(JSON.stringify(error))
      })
  }


  get emailstate(): boolean | null {
      if (this.email.length < 10) return null

      return true
    }

  get captchastate(): boolean | null {
      if (this.captcha.length > 4) return true

      return null
    }
}

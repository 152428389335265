

































































































































































































































































































































































































































































































































































































import Repository from "../Repository";

import { ProfileType } from '../types'

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Profile extends Vue {

  profile: ProfileType = new ProfileType()

  email = ""
  emailconfirm = ""
  pwd =  ""
  pwdconfirm = ""

  cancelreason = null

  // for pulldowns
  timezone = []
  statesprovinces = []
  cancelreasons = []

  // methods
  load() {
    Repository.get("profile")
      .then(res => {

      if (res.status == 205)  {
        this.$store.dispatch("logout_private")
      }

      if (typeof res.data.sms == "undefined") {
        res.data.sms = "";
      }
      if (typeof res.data.phone == "undefined") {
        res.data.phone = "";
      }


      this.profile = res.data;
      this.email = this.profile.email;
      this.emailconfirm = this.email;

      if (typeof this.profile.email == "undefined") {
        this.profile.email = "";
      }
    });
  }

    save() {

      if (this.profile.sms != "" && !this.validatePhone(this.profile.sms)) {
        this.$bvToast.toast("Text Phone does not appear to be valid",
          {
            title: "Warning",
            variant: 'warning'
          }
        )

        return
      }

      if (this.profile.phone != "" && !this.validatePhone(this.profile.phone)) {
        this.$bvToast.toast("Voice Phone does not appear to be valid",
          {
            title: "Warning",
            variant: 'warning'
          })

        return
      }

      return Repository.put("profile", this.profile)
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
          }

          this.$bvToast.toast(res.data.content, res.data.options)
          this.load()

          // stra
          let player = this.$refs.player as HTMLAudioElement
          console.log("Updating player")
          player.load()
     

          
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    }

    savepassword() {
      const emailpwd = {
        email: this.email,
        emailconfirm: this.emailconfirm,
        pwd: this.pwd,
        pwdconfirm: this.pwdconfirm
      }

      return Repository.put("profile/password", emailpwd)
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
          }

          this.$store.dispatch("passhashReset_clear")
          this.$bvToast.toast(res.data.content, res.data.options);

          this.load();
        })
        .catch(error => {
          console.log(JSON.stringify(error));
          Promise.reject(new Error("Profile did not save"));
        });
    }

  fancyDate(inDate: Date) {
      var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }

      var d = new Date(inDate)
      return d.toLocaleDateString("en-US", options)
    }
  
  validatePhone(phone:string | null | undefined) {

    if (phone == null || phone == "") return null

    if (phone.startsWith("(1") || phone.startsWith("1") || phone.startsWith("(0") || phone.startsWith("0"))  {
      return "is-invalid"
    }


    if (phone.replace(/\D/g,'').length >= 10) return "is-valid"

    return null
  }

  // computed
  get smsstate() {
      return this.validatePhone(this.profile.sms)
    }

  get phonestate() {
      return this.validatePhone(this.profile.phone)
    }

  get renewalEl () {
      return this.$store.getters.pref.renewalel
    }

  get menu_partners() {
      return this.$store.getters.pref.menu_partners
    }

  get isAdmin(): boolean {
      return this.$store.getters.isAdmin
    }

  get isPasshashReset(): boolean {
      return this.$store.getters.isPasshashReset
    }

  mounted() {

    this.load();

    Repository.get("timezone")
      .then(res => (this.timezone = res.data))
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

    Repository.get("statesprovinces")
      .then(res => {this.statesprovinces = res.data})

    Repository.get("cancelreason")
      .then(res => (this.cancelreasons = res.data))
  }
}

<template>
  <b-container>
    <b-row>
      <b-col 
        cols="12" 
        lg="6" 
        offset-lg="3"
      >
        <b-card
          header="New Customer Purchase"
          header-bg-variant="secondary"
          header-text-variant="white"
        >
          <b-form-group 
            label-cols-md="4" 
            label="Email" 
            description="required"
          >
            <b-form-input
              type="email"
              name="email"
              value=""
              maxlength="80"
              required
              aria-label="Email"
            />
          </b-form-group>

          <b-form-group
            label-cols-md="4"
            label="Email Confirm"
            description="required"
          >
            <b-form-input
              type="email"
              name="email_email"
              value=""
              maxlength="80"
              required
              aria-label="Email Confirm"
            />
          </b-form-group>

          <p class="h4">
            Membership Options
          </p>
          <b-table
            responsive
            hover
            table-variant="light"
            head-variant="light"
            :items="products"
            :fields="['name', 'price']"
          >
            <template v-slot:cell(price)="data">
              ${{ data.item.price }}
            </template>
          </b-table>

          <b-form-group label-cols-md="4">
            <b-button 
              size="lg" 
              variant="primary" 
              block 
              @click="doSignup"
            >
              Submit
            </b-button>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Repository from "../Repository";

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Purchase extends Vue {

  products = null

  doSignup() {
    this.$router.push("/signup");
  }

  mounted() {
    Repository.get("products").then(
      res => (this.products = res.data)
    );
  }
}
</script>

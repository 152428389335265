






































































































































































































































































































import Repository from "../Repository"
import { ProfileType } from '../types'

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Contacts extends Vue {

    profile: ProfileType = new ProfileType()

    id: number | null = null
    title:  string = ""
    name:   string = ""
    sms:    string =  ""
    phone:  string =  ""
    email:  string | null = null

    contactmethod = false

    addmethod = false;

    preaddcontact() {
      this.id = null
      this.title = "Add Emergency Contact"

      this.name = ""
      this.sms = ""
      this.phone = ""
      this.email = ""
      this.contactmethod = false

      // @ts-ignore:disable-next-line
      this.$refs["modal-contact"].show()
    }

    commitcontact(bvModalEvt:any) {
      // @ts-ignore:disable-next-line
      bvModalEvt.preventDefault()

      console.log("Doing commit")


      if (this.sms == "" && this.phone == "" && this.email == "") {
        this.addmethod = true
        return
      }

      if (this.sms != "" && !this.validatePhone(this.sms)) {
        this.$bvToast.toast("Text Phone does not appear to be valid",
          {
            title: "Warning",
            variant: 'warning'
          }
        )

        return
      }

      if (this.phone != "" && !this.validatePhone(this.phone)) {
        this.$bvToast.toast("Voice Phone does not appear to be valid",
          {
            title: "Warning",
            variant: 'warning'
          }
        )

        return
      }
      
      if (this.email != "" && !this.validateEmail(this.email)) {
        return
      }

      console.log("Passed validation")


      return Repository.post("contact", {
        id: this.id,
        name: this.name,
        sms: this.sms,
        phone: this.phone,
        email: this.email
      })
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
            this.$router.push({ path: "/login" })
          }

          // @ts-ignore:disable-next-line
          this.$refs["modal-contact"].hide()

          this.$bvToast.toast(res.data.content, res.data.options)

          Repository.get("profile")
            .then(res => (this.profile = res.data))
        })
        .catch(error => {
          console.log(JSON.stringify(error))
          console.error("Unable to load contacts")
        })
    }

    preeditcontact(row:number) {
      // we want to reload data just in case it changed along the way
      Repository.get("profile")
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
            this.$router.push({ path: "/login" })
          }

          this.profile = res.data

          console.log("Editing: " + row)

          let datarow = this.profile.contacts[row]

          this.title = "Edit Emergency Contact"

          // forcing to string on non existant causes problems. Endpoint better send strings
          this.id = datarow.id
          this.name = datarow?.name

          this.sms    = (typeof datarow.sms == "undefined" ?  "" : datarow.sms)
          this.phone  = (typeof datarow.phone == "undefined" ?  "" : datarow.phone)
          this.email  = (typeof datarow.email == "undefined" ?  "" : datarow.email)


          // @ts-ignore:disable-next-line
          this.$refs["modal-contact"].show()
        })
        .catch(error => {
          console.log(JSON.stringify(error))
          console.error("Unable to load contacts")
        })
    }


    deletecontact(id: number) {

      // @ts-ignore
      navigator.notification.confirm("Are you sure?", (btnIndex) => {
        if (btnIndex == 1) {
          console.log("Now deleting...")
          return Repository.delete("contact/" + id)
            .then(res => {
              if (res.status == 205)  {
                this.$store.dispatch("logout_private")
                this.$router.push({ path: "/login" })
              }

              this.$bvToast.toast(res.data.content, res.data.options)

              Repository.get("profile")
                .then(res => (this.profile = res.data))
                .catch(error => {
                  console.log(JSON.stringify(error))
                  console.error("Unable to load contacts")
                })
            })
            .catch(error => {
              console.log("Caught error" + JSON.stringify(error))
              Promise.reject(new Error("Contact did not delete"))
            })
        } // end callback function
      })
    }


    validateEmail(email:string | null) {

      if (email == null) return null

      var re = /\S+@\S+\.\S+/
      return re.test(String(email).toLowerCase())
    }

    validatePhone(phone:string | null) {

      if (phone == null || phone == "") return null

      if (phone.startsWith("(1") || phone.startsWith("1") || phone.startsWith("(0") || phone.startsWith("0"))  {
        return "is-invalid"
      }

      if (phone.replace(/\D/g,'').length >= 10) return "is-valid"

      return null
    }

  get smsstate() {
      return this.validatePhone(this.sms)
    }

  get phonestate() {
      return this.validatePhone(this.phone)
    }

  get emailstate() {
      if (this.email !== null && this.email.length < 10) return null
      if (this.email !== null && !this.validateEmail(this.email)) return false

      return true
    }


  get emailinvalid() {
      if (this.email !== null && !this.validateEmail(this.email)) return "This is not valid email"
      return ""
    }


  mounted() {

    this.$store.dispatch("restore")


    Repository.get("profile")
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.profile = res.data
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })
  }
}








































































import Repository from "../Repository";

import { IPet } from '../types'

import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Pets extends Vue {

  pets : Array<IPet> = []

    save() {


      for (var pet of this.pets) {
        if (typeof pet.count === "number" && pet.count > 99) {
          pet.count = 99
        }

        if (pet.count && typeof pet.count !== "string") {

          if (pet.count !== null && !Number.isSafeInteger(pet.count)) {
            this.$bvToast.toast("Data must be numbers only.", {
              title: "Error",
              variant: "danger"
            })
            return
          }
        }  
      }

      return Repository.put("pets", { data: this.pets })
        .then(res => {
          if (res.status == 205)  {
            this.$store.dispatch("logout_private")
          }

          this.$bvToast.toast(res.data.content, res.data.options);
          Repository.get("pets").then(res => (this.pets = res.data))
        })
        .catch(error => {
          console.log(JSON.stringify(error))
          this.$bvToast.toast("Pets did not save.", {
            title: "Error",
            variant: "danger"
          })
        })
    }



  mounted() {

    this.$store.dispatch("restore")


    Repository.get("pets")
      .then(res => {
        if (res.status == 205)  {
          this.$store.dispatch("logout_private")
        }

        this.pets = res.data
      })
      .catch(() => {
        this.$router.push({ path: "/dead" })
      })

  }
}
